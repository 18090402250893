/* eslint-disable no-lone-blocks */
/* eslint-disable react/no-array-index-key */
import React, { useMemo, useState } from "react";
import { Box, Button, Skeleton, Typography, styled } from "@mui/material";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import clsx from "clsx";
import moment from "moment";
import CancelSubscriptionDialog from "components/Dialogs/CancelSubscriptionDialog";
import { useOrg } from "pages/WorkspaceSettings";
import { BillingPlan, enterprisePlan, freePlan, paidPlans } from "utils/plans";

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "1rem",
  border: `1px solid ${theme.grey.light}`,
  borderRadius: "4px",
  padding: "0.5rem 1rem",
  "& .color-border": {
    height: "50px",
    width: "5px",
    borderRadius: "50px",
    backgroundColor: theme.palette.primary.main,
    "&.next": {
      backgroundColor: theme.palette.success.main,
    },
  },
  "& .actions": {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    "& .period": {
      padding: "0.5rem",
      border: "1px solid",
      borderRadius: "12px",
      "&.expiring": {
        borderColor: theme.palette.warning.dark,
        backgroundColor: `${theme.palette.warning.main}10`,
      },
      "&.next": {
        borderColor: "#1c54b2",
        backgroundColor: `#1c54b210`,
      },
    },
  },
}));

const Plan: React.FC<{
  lookupKey: string;
  canCancel?: boolean;
  subscriptionId?: string;
  isCanceled?: boolean;
  cancelTime?: string;
  isYearly?: boolean;
  showNext?: boolean;
  nextTime?: string;
  loading?: boolean;
}> = ({
  lookupKey,
  canCancel,
  subscriptionId,
  isCanceled,
  cancelTime,
  isYearly,
  showNext,
  nextTime,
  loading,
}) => {
  const { org: currentOrg } = useOrg();
  const [cancelPlanDialog, setCancelPlanDialog] = useState<boolean>(false);

  const planToShow = useMemo(() => {
    if (lookupKey === "freeplan") return freePlan;
    if (lookupKey === "enterpriseplan") return enterprisePlan;

    return paidPlans.find((p) =>
      [p.monthly_lookup_key || p.yearly_lookup_key].includes(lookupKey)
    ) as BillingPlan;
  }, [lookupKey]);

  return (
    <>
      <Wrapper>
        <Box className={clsx("color-border", showNext ? "next" : "")} />
        <Box>
          {!loading ? (
            <>
              <Typography variant="h6">{planToShow.name}</Typography>
              <Typography variant="body1" color="textSecondary">
                {planToShow.monthly_lookup_key !== "enterpriseplan"
                  ? isYearly
                    ? `₹${planToShow.yearly_price}/year`
                    : `₹${planToShow.monthly_price}/month`
                  : "Custom billing ₹"}
              </Typography>
            </>
          ) : (
            <>
              <Skeleton variant="rectangular" width={150} height={22} />
              <Skeleton
                sx={{ mt: "0.9rem" }}
                variant="rectangular"
                width={130}
                height={20}
              />
            </>
          )}
        </Box>
        <Box className="actions">
          {isCanceled && cancelTime && (
            <Typography fontSize="small" className="period expiring">
              Expiring: {moment(cancelTime).format("MMMM D, YYYY, hh:mm:ss A")}
            </Typography>
          )}
          {showNext && nextTime && (
            <Typography fontSize="small" className="period next">
              Starting: {moment(nextTime).format("MMMM D, YYYY, hh:mm:ss A")}
            </Typography>
          )}
          {(canCancel || loading) && (
            <Button
              disabled={loading}
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => {
                setCancelPlanDialog(true);
              }}
              startIcon={<ClearOutlinedIcon fontSize="small" />}
            >
              Cancel
            </Button>
          )}
        </Box>
      </Wrapper>
      <CancelSubscriptionDialog
        open={cancelPlanDialog}
        organization={currentOrg}
        subscriptionId={subscriptionId || ""}
        close={() => {
          setCancelPlanDialog(false);
        }}
      />
    </>
  );
};

export default Plan;
