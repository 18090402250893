/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
import React, { useRef, useState } from "react";
import {
  Box,
  ClickAwayListener,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popper,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  selectCurrentOrganizationId,
  selectUser,
} from "store/features/session/slice";
import {
  BookmarkBorderOutlined,
  StarOutlineOutlined,
} from "@mui/icons-material";
import { useDocumentCollections } from "api/documentCollectionService";
import { DocumentCollection } from "models/api/response.types";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import { TreeItem, TreeItemProps, TreeView, useTreeItem } from "@mui/lab";
import clsx from "clsx";
import {
  searchChildCollectionTree,
  searchParentCollectionTreeName,
} from "utils/collections";
import { isGuest } from "models/components/Permissions.models";
import { useUsers } from "api/userService";
import CollectionDialog from "components/Dialogs/CollectionDialog";
import { useOrganizationUsage } from "api/organizationService";
import RestrictionPopover from "components/Dialogs/RestrictionPopover";
import { defaultCollectionsTypes } from "models/components/Browse.models";
import { companyFeatures } from "company-config";
import { isCollectionLimitExceeded } from "utils/plans";

interface Props {
  size?: string;
}

// styles as default textfield
const Container = styled(Box)<Props>(({ size, theme }) => ({
  background: theme.background.light,
  border: `1px solid rgba(0, 0, 0, 0.23)`,
  padding: "8.5px 14px",
  cursor: "pointer",
  "&:hover": {
    border: `1px solid rgba(0, 0, 0, 0.87)`,
  },
  "&.disabled": {
    cursor: "default",
    "& .title": {
      color: theme.palette.text.secondary,
    },
  },
  ...(size === "small" && {
    padding: "5px 10px",
    border: `1px solid ${theme.grey.light}`,
    "&:hover": {
      border: `1px solid ${theme.grey.main}`,
    },
  }),
  "&.selected": {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  display: "flex",
  justifyContent: "space-between",
  flex: 1,
  borderRadius: "4px",
  alignItems: "center",
  "& .title": {
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const CollectionContainer = styled(ListItemButton)(() => ({
  "&.collection-item": {
    padding: "6px 16px",
    "&.small": {
      padding: "0px 16px",
      height: "32px",
      "& .MuiListItemIcon-root": {
        marginRight: "1.2rem",
        "& svg": {
          height: "20px",
          width: "20px",
        },
      },
    },
    "& .MuiListItemIcon-root": {
      minWidth: "auto",
      marginRight: "1rem",
      "& .MuiSvgIcon-root.MuiSvgIcon-colorPrimary": {
        color: "rgba(0, 0, 0, 0.54)",
      },
    },
    "&.no-icon": {
      "& .MuiListItemIcon-root": {
        marginRight: "2.5rem",
      },
    },
    "& .special-icon": {
      marginRight: "1.25rem",
    },
  },
}));

const CollectionTreeItem = React.forwardRef(function CustomContent(
  props: any,
  ref
) {
  const {
    size,
    classes,
    className,
    collectionLabel,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
    expandCollection,
    selectedCollection,
    handleCollectionChange,
  } = props;
  const { expanded, handleSelection } = useTreeItem(nodeId);
  const id = parseInt(nodeId, 10);
  const user = useSelector(selectUser);
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const icon = iconProp || expansionIcon || displayIcon;
  const isCustom = defaultCollectionsTypes.includes(collectionLabel);
  const { users } = useUsers(currentOrganizationId);
  const currentUserRole = users?.find((u) => u.id === user?.id)?.role;
  const enableDocumentActions =
    companyFeatures.app.guests.enableDocumentActions ||
    !isGuest(currentUserRole);

  const handleSelectionClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    handleSelection(event);
    handleCollectionChange(id);
  };

  if (!enableDocumentActions && collectionLabel === "Read later") {
    return null;
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <CollectionContainer
      className={clsx(className, "collection-item", {
        [classes.expanded]: expanded,
        "no-icon": !icon,
        [size]: size,
      })}
      key={id}
      selected={selectedCollection?.id === id}
      onClick={handleSelectionClick}
    >
      {!isCustom ? (
        <ListItemIcon
          onClick={(e: any) => {
            e.stopPropagation();
            expandCollection(nodeId);
          }}
        >
          {icon}
        </ListItemIcon>
      ) : (
        <>
          {collectionLabel === "Favorites" ? (
            <StarOutlineOutlined
              className="special-icon"
              fontSize="small"
              color="warning"
            />
          ) : collectionLabel === "Read later" ? (
            <BookmarkBorderOutlined
              color="action"
              className="special-icon"
              fontSize="small"
            />
          ) : null}
        </>
      )}
      <ListItemText>
        <Typography variant="body2">{collectionLabel}</Typography>
      </ListItemText>
    </CollectionContainer>
  );
});

const CollectionSelector: React.FC<{
  includeDefaultCollections?: boolean;
  canCreateCollection?: boolean;
  size?: string;
  disabled?: boolean;
  // collectionId is taken from collection dialog
  collectionId?: number;
  currentParentId: number;
  collectionToUse: (collId?: number) => void;
}> = ({
  includeDefaultCollections,
  canCreateCollection,
  size,
  disabled,
  collectionId: collId,
  currentParentId: collectionId,
  collectionToUse,
}) => {
  const theme = useTheme();
  const currentOrganizationId = useSelector(selectCurrentOrganizationId);
  const user = useSelector(selectUser);
  const { defaultCollections, customCollections } = useDocumentCollections(
    currentOrganizationId
  );
  const { users } = useUsers(currentOrganizationId);
  const currentUserRole = users?.find((u) => u.id === user?.id)?.role;
  const collectionsToRemove = collId
    ? searchChildCollectionTree(collId, customCollections)
    : [];
  const collectionsToUse =
    customCollections && defaultCollections
      ? includeDefaultCollections
        ? [...defaultCollections, ...customCollections]
        : [...customCollections]
      : [];
  const collections = collectionsToUse.filter(
    (collection) => !collectionsToRemove.includes(collection.id)
  );
  const { organizationUsage } = useOrganizationUsage(currentOrganizationId);
  const customInputRef = useRef<HTMLDivElement>(null);
  const [openCreateCollectionDialog, setOpenCreateCollectionDialog] =
    useState<boolean>(false);
  const [collectionExpanded, setCollectionExpanded] = useState<string[]>([]);
  const [menuOpen, setMenuOpen] = useState<null | HTMLElement>(null);
  const [collectionExceededPopover, setCollectionExceededPopover] =
    useState<Element | null>(null);
  const collection = collections
    ? collections[collections.findIndex((coll) => coll.id === collectionId)]
    : undefined;
  // unlimited collections are only comes with paid tier
  const isCollectionsExceeded = isCollectionLimitExceeded(
    organizationUsage?.usage_limits?.ai_credit_limit,
    customCollections?.length || 0
  );

  const handleCollectionChange = (id?: number) => {
    setMenuOpen(null);
    collectionToUse(id);
  };

  const expandCollection = (nodeId: string) => {
    if (collectionExpanded.includes(nodeId)) {
      setCollectionExpanded(
        collectionExpanded.filter((node: string) => node !== nodeId)
      );
    } else {
      setCollectionExpanded([...collectionExpanded, nodeId]);
    }
  };

  const CustomTreeItem = (props: TreeItemProps) => (
    <TreeItem ContentComponent={CollectionTreeItem} {...props} />
  );

  const renderTreeItem = (item: DocumentCollection, lvl: number) => {
    if (collections) {
      const children = collections.filter((coll) => {
        return coll.parent_id === item.id;
      });

      const generateSubLogic = () => {
        return children.map((child) => renderTreeItem(child, lvl + 1));
      };

      if (item.parent_id > 0 && lvl === 0) {
        return null;
      }

      return (
        <CustomTreeItem
          key={item.id}
          nodeId={item.id.toString()}
          ContentProps={
            {
              size,
              handleCollectionChange,
              expandCollection,
              collectionLabel: item.name,
              selectedCollection: collection,
            } as any
          }
        >
          {generateSubLogic()}
        </CustomTreeItem>
      );
    }
    return null;
  };

  return (
    <>
      <Container
        size={size}
        className={clsx("collection-selector", {
          disabled,
          selected: menuOpen,
        })}
        ref={customInputRef}
        onClick={(e) => {
          if (!disabled) {
            setMenuOpen(e.currentTarget);
          }
        }}
      >
        <Typography variant="body2" className="title">
          {collection
            ? `${searchParentCollectionTreeName(collection, collections).join(
                " > "
              )}`
            : "All documents"}
        </Typography>
        <KeyboardArrowDownIcon
          color="action"
          sx={
            menuOpen
              ? {
                  transform: "rotate(180deg)",
                }
              : {}
          }
        />
      </Container>
      {menuOpen && (
        <ClickAwayListener onClickAway={() => setMenuOpen(null)}>
          <Popper
            open={!!menuOpen}
            anchorEl={menuOpen}
            placement="bottom-start"
            sx={{
              padding: "0.5rem 0",
              borderRadius: "4px",
              border: `1px solid #E9E9E9`,
              boxShadow: `0 0.5rem 1rem rgba(149, 157, 165, 0.2)`,
              maxHeight: "300px",
              width: customInputRef.current
                ? `${customInputRef.current.offsetWidth}px`
                : "300px",
              zIndex: 1301,
              backgroundColor: theme.background.light,
              overflow: "auto",
            }}
          >
            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon color="primary" />}
              defaultExpandIcon={<ChevronRightIcon color="primary" />}
              expanded={collectionExpanded}
            >
              {!isGuest(currentUserRole) && canCreateCollection && (
                <ListItemButton
                  sx={{
                    padding: "6px 16px",
                    height: "36px",
                    ...(size === "small" && {
                      padding: "0px 16px",
                      height: "32px",
                    }),
                    background: theme.background.secondary,
                  }}
                  onClick={() => {
                    if (isCollectionsExceeded) {
                      setCollectionExceededPopover(customInputRef.current);
                      setMenuOpen(null);
                    } else {
                      setOpenCreateCollectionDialog(true);
                      setMenuOpen(null);
                    }
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      marginRight: "1.2rem",
                    }}
                  >
                    <AddIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="body2">Add new collection</Typography>
                  </ListItemText>
                </ListItemButton>
              )}
              <ListItemButton
                selected={!collectionId}
                sx={{
                  padding: "6px 16px",
                  height: "36px",
                  ...(size === "small" && {
                    padding: "0px 16px",
                    height: "32px",
                  }),
                }}
                onClick={() => {
                  handleCollectionChange(undefined);
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    marginRight: "1.2rem",
                  }}
                >
                  <AutoStoriesOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body2">All documents</Typography>
                </ListItemText>
              </ListItemButton>
              {collections.map((item) => renderTreeItem(item, 0))}
            </TreeView>
          </Popper>
        </ClickAwayListener>
      )}
      {collectionExceededPopover && (
        <RestrictionPopover
          organizationId={currentOrganizationId || 0}
          accessMessage="add more collections"
          upgradeMessage="add"
          planTier={300}
          anchor={collectionExceededPopover}
          onClose={() => {
            setCollectionExceededPopover(null);
          }}
        />
      )}
      {openCreateCollectionDialog && (
        <CollectionDialog
          selectCreatedCollection
          editCollectionId={-1}
          setOpen={(id?: number) => {
            if (id) {
              collectionToUse(id);
            }
            setOpenCreateCollectionDialog(false);
          }}
        />
      )}
    </>
  );
};

export default CollectionSelector;
