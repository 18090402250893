import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Organization } from "models/api/response.types";
import { useOrganizationUsage } from "api/organizationService";
import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addAlert } from "store/features/general/slice";

const CancelSubscriptionDialog: React.FC<{
  organization: Organization;
  subscriptionId: string;
  isAddon?: boolean;
  open: boolean;
  close: () => void;
}> = ({ organization, isAddon, subscriptionId, close, open }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { organizationUsage, usageQueryKey } = useOrganizationUsage(
    organization.id
  );

  const onSubmit = () => {
    if (organizationUsage) {
      axios
        .post("/api/payment/cancel_subscription", {
          organization_id: organization.id,
          subscription_id: subscriptionId,
        })
        .then(() => {
          queryClient.invalidateQueries(usageQueryKey);
          dispatch(
            addAlert({
              severity: "success",
              autoHideDuration: 5000,
              alert: {
                message: "Subscription has been canceled successfully.",
              },
            })
          );
          close();
        })
        .catch(() => {
          dispatch(
            addAlert({
              severity: "error",
              autoHideDuration: 5000,
              alert: {
                message: "Something went wrong, please try again.",
              },
            })
          );
        });
    }
  };
  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="sm">
      <DialogTitle>
        Cancel {isAddon ? "Add-On" : "Plan"} Confirmation
        <IconButton onClick={close}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" color="textSecondary">
          Are you sure you want to cancel your {isAddon ? "add-on" : "plan"}. By
          cancelling, you will no longer have access to the benefits and
          services associated with this {isAddon ? "add-on" : "plan"} after the
          end of your current billing cycle. This action cannot be undone. Any
          fees paid are valid up to the last billing cycle. If you have any
          concerns or would like to discuss alternative options, please contact
          our support team for assistance.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onSubmit}
          color="error"
          size="medium"
          variant="contained"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelSubscriptionDialog;
