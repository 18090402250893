import { companyFeatures } from "company-config";
/* eslint-disable import/prefer-default-export */

export interface BillingPlan {
  name: string;
  description: string;
  monthly_price: string;
  yearly_price: string;
  monthly_lookup_key: string;
  yearly_lookup_key: string;
  features: string[];
}

export const freePlan: BillingPlan = {
  name: "Free",
  description: "Free plan",
  monthly_price: "0",
  yearly_price: "0",
  monthly_lookup_key: "freeplan",
  yearly_lookup_key: "freeplan",
  features: [
    "1 Admin + 1 Guest user",
    "500 Credits/Month",
    "Pre built templates",
    "Single Doc Chat",
    "Export APIs - in PDF, Excel, Doc etc.",
    "On demand support",
  ],
};

export const paidPlans: BillingPlan[] = [
  {
    name: "Student",
    description: "For ICAI Student",
    monthly_price: "1499",
    yearly_price: "16489",
    monthly_lookup_key: "studentplan",
    yearly_lookup_key: "yearlystudentplan",
    features: [
      "1 Admin + 1 Guest",
      "500 Credits/Month",
      "Pre built templates",
      "Single Doc Chat",
      "Export APIs - in PDF, Excel, Doc etc.",
      "On demand Support",
    ],
  },
  {
    name: "Professional",
    description: "For ICAI Professionals",
    monthly_price: "2499",
    yearly_price: "27489",
    monthly_lookup_key: "proplan",
    yearly_lookup_key: "yearlyproplan",
    features: [
      "1 Admin + 3 Guests",
      "1000 Credits/Month",
      "Pre built templates",
      "Single Doc Chat",
      "Multi Doc Chat",
      "Smart AI Assistant",
      "Export APIs - in PDF, Excel, Doc etc.",
      "On demand Support",
    ],
  },
  {
    name: "Team",
    description: "For ICAI Teams",
    monthly_price: "19990",
    yearly_price: "219990",
    monthly_lookup_key: "teamplan",
    yearly_lookup_key: "yearlyteamplan",
    features: [
      "1 Admin + 10 Members + 50 Guests",
      "10000 Credits/Month",
      "Pre built templates",
      "Single Doc Chat",
      "Multi Doc Chat",
      "Smart AI Assistant",
      "Export APIs - in PDF, Excel, Doc etc.",
      "On demand Support",
    ],
  },
];

export const enterprisePlan: BillingPlan = {
  name: "Enterprise",
  description: "For ICAI Firms",
  monthly_price: "999999",
  yearly_price: "999999",
  monthly_lookup_key: "enterpriseplan",
  yearly_lookup_key: "enterpriseplan",
  features: [
    "1 Admin + Multiple user",
    "Custom Limit",
    "Pre built templates",
    "Single Doc Chat",
    "Multi Doc Chat",
    "Smart AI Assistant + Private GPT",
    "Export APIs - in PDF, Excel, Doc etc.",
    "Priority Support",
  ],
};

// we can get current plan base on ai credit limit
// notice any further changes on ai credit limit will
// reflect this object
export const AI_CREDIT_PLAN_KEY: { [key: number]: BillingPlan } = {
  300: freePlan,
  500: paidPlans[0],
  1000: paidPlans[1],
  10000: paidPlans[2],
};

const priceOrder = [
  "studentplan",
  "yearlystudentplan",
  "proplan",
  "yearlyproplan",
  "teamplan",
  "yearlyteamplan",
];

export const comparePlans = (key1?: string, key2?: string) => {
  if (!key1 || !key2) {
    return ["error", "error"];
  }

  const key1Index = priceOrder.indexOf(key1);
  const key2Index = priceOrder.indexOf(key2);
  // $ monthly to $$$ monthly - immediate upgrade prorate
  // $ yearly to $$$ yearly - prorate normally
  const isYearToYearSubscription =
    key1.includes("yearly") && key2.includes("yearly");
  const isMonthToMonthSubscription =
    !key1.includes("yearly") && !key2.includes("yearly");

  if (isYearToYearSubscription || isMonthToMonthSubscription) {
    // upgrade from same type
    if (key1Index < key2Index) {
      return ["upgrade", "immediate"];
    }
    // donwgrade from same type
    return ["downgrade", "end of period"];
  }

  if (!key1.includes("yearly") && key2.includes("yearly")) {
    // $ monthly to $$$ yearly - immediate upgrade prorate
    if (key1Index < key2Index) {
      return ["upgrade", "immediate"];
    }
    // $$$ monthly to $ yearly - $$$ until end then switch
    if (key1Index > key2Index) {
      return ["downgrade", "end of period"];
    }
    // $ monthly to $ yearly - end of month
    // $$$ monthly to $$$ yearly - start yearly plan at end of month
    if (key1Index === key2Index) {
      return ["switch", "end of period"];
    }
  }

  if (key1.includes("yearly") && !key2.includes("yearly")) {
    // $ yearly to $$$ monthly - contact us
    if (key1Index < key2Index) {
      return ["contact us", "contact us"];
    }
    // $$$ yearly plan to $ monthly plan - service till end then switch to monthly
    if (key1Index > key2Index) {
      return ["downgrade", "end of period"];
    }

    // $$$ yearly plan to $$$ monthly plan - service till end then switch to monthly
    // $ yearly to $ monthly - service till end then switch to monthly
    if (key1Index === key2Index) {
      return ["switch", "end of period"];
    }
  }

  return ["error", "error"];
};

export const isFreePlan = (credit_limit: number) => {
  return AI_CREDIT_PLAN_KEY[credit_limit]?.name === "Free";
};

export const isCollectionLimitExceeded = (
  credit_limit: number,
  customCollectionsLength: number
) => {
  return customCollectionsLength >= 2 && isFreePlan(credit_limit);
};

export const isAnnotationLimitExceeded = (
  credit_limit: number,
  annotationLength: number,
  type: "create" | undefined
) => {
  return annotationLength >= 3 && isFreePlan(credit_limit) && type === "create";
};

export const canUseMultidocChat = (aiCreditLimit: number) => {
  return aiCreditLimit >= 1000;
};

export const canUseAiCreate = (aiCreditLimit: number) => {
  return aiCreditLimit >= 1000;
};

export const isTrialExist = (org_created_time?: string) => {
  // trial is not valid for white label projects
  if (!companyFeatures.app.isTrialExists) {
    return false;
  }

  const currentDate: Date = new Date();

  // check if org created time less than 7 days
  if (org_created_time) {
    const passedDate: Date = new Date(org_created_time);
    const timeDifferencePassed: number =
      currentDate.getTime() - passedDate.getTime();
    const daysDifferencePassed: number =
      timeDifferencePassed / (1000 * 60 * 60 * 24);
    if (daysDifferencePassed < 7) {
      return true;
    }
  }

  return false;
};
